.reasons {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.reasons-bg {
  position: absolute;
  top: 0px;
  left: -10000000px;
  right: 0;
  bottom: -120px;
  background-color: #f2f4f8;
}

.reasons__left {
  position: relative;
  display: block;
  margin-right: 55px;
  margin-top: -12px;
}

.reasons__title {
  font-family: "Jost", sans-serif;
  font-size: 50px;
  font-weight: 900;
  line-height: 60px;
  margin-bottom: 48px;
}

.reasons__content-title {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.reasons__content-text {
  font-size: 16px;
  margin: 0;
  font-family: "Jost", sans-serif;
  color: #726f84;
  line-height: 34px;
  font-weight: 500;
}

.reasons__list-box {
  position: relative;
  display: block;
}

.reasons__list-box li:first-child {
  margin-top: 0;
}

.reasons__list-box li {
  font-family: "Jost", sans-serif;
  position: relative;
  display: block;
  padding-left: 99px;
  margin-top: 49px;
  padding-bottom: 42px;
}

.reasons__icon {
  height: 58px;
  width: 58px;
  background-color: #123962;
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 500ms ease;
}

.reasons__icon span {
  display: block;
  transform: scale(1);
  transition: transform 500ms ease;
}

[className^="icon-"],
[className*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.reasons__img-box {
  position: relative;
  display: block;
}

.reasons__img {
  position: relative;
  display: block;
  margin-right: -200px;
  background-color: #0f0d1d;
}

.reasons__img > img {
  width: 100%;
  /* mix-blend-mode: luminosity; */
}

img,
svg {
  vertical-align: middle;
}

.reasons__list-box li:before {
  position: absolute;
  bottom: 0;
  left: 100px;
  right: 0;
  content: "";
  height: 1px;
  background-color: rgba(15, 13, 29, 0.1);
}

.reasons__list-box li:after {
  position: absolute;
  top: 58px;
  bottom: -50px;
  left: 29px;
  width: 1px;
  content: "";
  background-color: #123962;
}

.reasons__list-box li:last-child:after {
  display: none;
}

.reasons__list-box li:first-child {
  margin-top: 0;
}

.reasons__list-box li:last-child {
  padding-bottom: 0px;
}

.reasons__list-box li:last-child:before {
  display: none;
}

@media (max-width: 1650px) {
  .reasons__img {
    width: 99%;
  }

  /* .reasons-bg {
    right: 0px;
  } */

  /* .reasons__list-box li:after {
    display: none;
  } */
}

@media (max-width: 900px) {
  .reasons__img {
    display: none;
  }

  .reasons-bg {
    right: 0px;
  }

  .reasons__list-box li:after {
    display: none;
  }
}
