.real-world {
  position: relative;
  display: block;
  background-color: #0f0d1d;
  padding: 120px 0 90px;
}

.real-world .section-title {
  position: relative;
  display: block;
  z-index: 2;
}

.section-title {
  margin-top: -12px;
  margin-bottom: 50px;
}

.real-world .section-title__title {
  color: white;
}
.real-world-shape {
  background-image: url(../../assets/shapes/real-world-shape.png);
  visibility: visible;
  animation-duration: 2500ms;
  animation-delay: 100ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 610px;
  height: 595px;
  opacity: 0.08;
}

.section-title__title {
  font-family: "Jost", sans-serif;
  margin: 0;
  font-weight: 900;
  color: #0f0d1d;
  font-size: 50px;
  line-height: 60px;
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: var(--thm-letter-space-small);
}

.real-world .section-title__tagline {
  color: #8f8da0;
}

.section-title__tagline {
  font-family: "Jost", sans-serif;
  position: relative;
  display: inline-block;
  color: #726f84;
  font-size: 18px;
  align-items: center;
  line-height: 30px;
}

.real-world__single {
  position: relative;
  display: block;
  background-color: #06050c;
  padding: 54px 60px 75px;
  margin-bottom: 30px;
  transition: background 500ms ease;
}
.real-world__single:hover {
  position: relative;
  display: block;
  background-color: #123962;
  padding: 54px 60px 75px;
  margin-bottom: 30px;
  transition: background 500ms ease;
}

.real-world__single:hover .real-world__icon-box {
  transform: scale(0.8);
}

.real-world__title {
  font-size: 24px;
  color: white;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 20px;
  letter-spacing: -0.02em;
}

.real-world__icon-box {
  position: absolute;
  bottom: 52px;
  right: 60px;
  transition: transform 500ms ease;
}

.real-world__btn {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #8f8da0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  overflow: hidden;
}

.real-world__btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  transition: width 500ms ease;
}

.real-world__btn:hover:before {
  width: 100%;
}
.real-world__title a {
  color: inherit;
  transition: all 500ms ease;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    87% / 0px 2px no-repeat;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

.real-world__title a:hover {
  background-size: 100% 2px;
}

@media (max-width:600px) {
    .real-world-shape {
    width: 0px;
}
}