.main-slider-bg-one-img {
  background-image: url(../../assets/backgrounds/main-slider-1-1.jpg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  transition: transform 7000ms ease;
  z-index: 1;
  background-blend-mode: luminosity;
  opacity: 0.9;
  background-position: center center;
}
.main-slider-bg-two-img {
  background-image: url(../../assets/backgrounds/main-slider-1-2.jpg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  transition: transform 7000ms ease;
  z-index: 1;
  background-blend-mode: luminosity;
  opacity: 0.9;
  background-position: center center;
}
.main-slider-bg-three-img {
  background-image: url(../../assets/backgrounds/main-slider-1-3.jpg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  transition: transform 7000ms ease;
  z-index: 1;
  background-blend-mode: luminosity;
  opacity: 0.9;
  background-position: center center;
}
.main-slider-bg-four-img {
  background-image: url(../../assets/backgrounds/main-slider-1-4.jpg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  transition: transform 7000ms ease;
  z-index: 1;
  background-blend-mode: luminosity;
  opacity: 0.9;
  background-position: center center;
}

.main-slider-one-left {
  position: relative;
  z-index: 30;
  padding: 10em 5em;
}
.main-slider-two-left {
  position: relative;
  z-index: 30;
  padding: 10em 5em;
}
.main-slider-three-left {
  position: relative;
  z-index: 30;
  padding: 10em 5em;
}
.main-slider-four-left {
  position: relative;
  z-index: 30;
  padding: 10em 5em;
}

.main-slider P {
  font-size: 14px;
  position: relative;
  font-weight: 600;
  color: white;
  letter-spacing: 0.1em;
  background-color: rgba(60, 60, 60, .10);
  display: inline-block;
  padding: 8px 30px;
  margin: 0;
}

.main-slider h2 {
  font-family: "Jost", sans-serif;
  margin: 0;
  margin-bottom: 30px;
  color: white;
  font-weight: 900;
  font-size: 70px;
  line-height: 105px;
  padding-top: 32px;
}

.thm-btn {
  font-family: "Jost", sans-serif;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  background-color: #123962;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 18px 50px;
  transition: 500ms;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.thm-btn:hover {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  background-color: #041930;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 18px 50px;
  transition: 500ms;
  letter-spacing: 0.1em;
  text-decoration: none;
}

[class*="main-slider-shape-"] {
  position: absolute;
  width: 889px;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  opacity: 0.8;
}

.main-slider-shape-1 {
  background-image: url(../../assets/shapes/main-slider-shape-1.png);
  z-index: 10;
  mix-blend-mode: overlay;
}
.main-slider-shape-2{
  background-image: url(../../assets/shapes/main-slider-shape-2.png);
  z-index: 10;
  mix-blend-mode: overlay;
}
.main-slider-shape-3{
  background-image: url(../../assets/shapes/main-slider-shape-3.png);
  z-index: 10;
  mix-blend-mode: overlay;
}
.main-slider-shape-4{
  background-image: url(../../assets/shapes/main-slider-shape-4.png);
  z-index: 10;
  mix-blend-mode: overlay;
}
.main-slider-shape-5{
  background-image: url(../../assets/shapes/main-slider-shape-5.png);
  z-index: 10;
  mix-blend-mode: overlay;
}


@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 0.8;
  }
}

[class*="main-slider-shape-"] {
  position: absolute;
  width: 889px;
  height: 100%;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  animation: slideInRight 1s ease-out;
  animation-fill-mode: forwards;
}

/* Adjust animation durations for each shape */
.main-slider-shape-1 {
  animation-duration: 0.8s;
}

.main-slider-shape-2 {
  animation-duration: 1s;
}

.main-slider-shape-3 {
  animation-duration: 1.2s;
}

.main-slider-shape-4 {
  animation-duration: 1.4s;
}

.main-slider-shape-5 {
  animation-duration: 1.6s;
}

@media (max-width:1400px) {
  .main-slider-shape-1 {
    display: none;
  }
  .main-slider-shape-2 {
    display: none;
  }
  .main-slider-shape-3 {
    display: none;
  }
  .main-slider-shape-4 {
    display: none;
  }
  .main-slider-shape-5 {
    display: none;
  }
  .main-slider h2 {
    font-size: 20px;
    line-height: 50px;
}
}