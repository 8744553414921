.footer {
  background-color: #0f0d1d;
  position: relative;
  padding-top: 5em;
}

.font-jost {
  font-family: "Jost", sans-serif;
}

.footer-p {
  font-family: "Jost", sans-serif;
  color: #8f8da0;
  font-size: 16px;
}

.footer-top-left {
  position: relative;
  display: block;
  margin-right: 105px;
}

.contact-list {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-li {
  display: flex;
  gap: 20px;
}

.footer-li .icon {
  color: #00e0d8;
}

.footer-backgroundImage {
  background-image: url(../assets/shapes/footer-shape.png);
  height: 504px;
  background-repeat: no-repeat;
  position: absolute;
  width: 700px;
  top: 0;
  right: 0;
  opacity: 0.08;
}