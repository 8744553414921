.services-one {
    position: relative;
    display: block;
    padding: 120px 0 90px;
    z-index: 1;
    font-family: "Jost", sans-serif;
}


.section-title {
    margin-top: -12px;
    margin-bottom: 50px;
}

.section-title__title {
    margin: 0;
    font-weight: 900;
    color: #0f0d1d;
    font-size: 50px;
    line-height: 60px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.services-one__single {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.services-one__img {
    position: relative;
    display: block;
    overflow: hidden;
}

.services-one__img>img {
    width: 100%;
    mix-blend-mode: luminosity;
    transition: all 500ms ease;
    transform: scale(1);
}

.services-one__img>img:hover{
    transform: scale(1.1);
}

.services-one__content {
    position: relative;
    display: block;
    padding: 42px 50px 50px;
    background-color: #ffffff;
    box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, .07);
}

.services-one__text {
    font-size: 16px;
    margin: 0;
    padding-top: 21px;
    padding-bottom: 14px;
}

.services-one__btn {
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: #0f0d1d;
    text-transform: uppercase;
    transition: all 500ms ease;
}

.services-one__btn:before {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 10px;
    content: "";
    background-color: currentColor;
    transition: width 500ms ease;
}