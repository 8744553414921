.counters-one {
    position: relative;
    display: block;
    background-color: #0f0d1d;
}

.counters-one-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.02;
}

.counters-one__box {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding: 120px 0 204px;
}

.counter-one__single {
    position: relative;
    display: inline-block;
    max-width: 280px;
    width: 100%;
    text-align: center;
}

.counter-one__icon {
    position: relative;
    display: flex;
    height: 110px;
    width: 110px;
    margin: 0 auto;
    background-color: #06050c;
    align-items: center;
    justify-content: center;
}

.counter-one__text {
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
}

.counter-one__single h3 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 900;
    line-height: 50px;
    margin-bottom: 4px;
    margin-top: 25px;
    font-family: var(--thm-font) !important;
    letter-spacing: var(--thm-letter-space-small);
}

.counter-one__single:before {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 9px;
    content: "";
    width: 1px;
    background-color: rgb(255, 255, 255, .1);
}

.counter-one__icon span {
    font-size: 65px;
    color: #fab10c;
    line-height: 65px;
    display: block;
    transform: scale(1);
    transition: all 500ms ease;
}

.counter-one__single:hover .counter-one__icon span{
    transform: scale(.85);
}