.sticky-header .main-header{
  transform: translateY(-120%);
}

.sticky-header.show .main-header {
  transform: translateY(0);
}

@media (max-width: 1400px) {
  .sticky-header{
    display: none;
  }
}