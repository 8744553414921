.cases-details {
    position: relative;
    display: block;
    padding-top: 120px;
    font-family: "Jost",sans-serif;
}

.cases-details__img {
    position: relative;
    display: block;
    margin-bottom: 50px;
    background-color: #0f0d1d;
}

.cases-details__img > img {
    width: 100%;
    /* mix-blend-mode: luminosity; */
}

.cases-details__icon span {
    font-size: 65px;
    color: #123962;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cases-details__title {
    font-size: 36px;
    font-weight: 800;
    line-height: 46px;
    text-transform: uppercase;
    margin-top: 13px;
    margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6 {
    color: #0f0d1d;
    margin: 0;
}

.cases-details__text-1 {
    font-size: 16px;
    margin: 0;
    line-height: 32px;
    padding-bottom: 30px;
    color: #726f84;
    line-height: 34px;
    font-weight: 500;
}

.cases-details__text-2 {
    font-size: 16px;
    margin: 0;
    line-height: 32px;
    color: #726f84;
    line-height: 34px;
    font-weight: 500;
}