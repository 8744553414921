.services-details {
    font-family: "Jost",sans-serif;
    position: relative;
    display: block;
    padding: 120px 0 109px;
}

.services-details__sidebar {
    position: relative;
    display: block;
}

.services-details__services-list-box {
    position: relative;
    display: block;
    background-color: #f2f4f8;
    padding: 43px 30px 34px;
}

.services-detials__categories {
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 30px;
    margin-bottom: 7px;
    padding-left: 20px;
}

.services-details__services-list {
    position: relative;
    display: block;
}

.services-details__services-list li span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    display: flex;
    align-items: center;
    transition: all 500ms ease;
    font-size: 20px;
    transform: scale(0);
}

.services-details__services-list li a {
    position: relative;
    font-size: 16px;
    color: var(--thm-gray);
    font-weight: 500;
    display: block;
    background-color: var(--thm-light);
    padding: 4.5px 20px;
    transition: all 500ms ease;
}

.services-details__services-list li {
    position: relative;
    display: block;
}

.services-details__help-box-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    background-color: #123962;
    background-blend-mode: luminosity;
    opacity: 0.1;
}

.services-details__services-list li.active a, .services-details__services-list li:hover a {
    background-color: #ffffff;
    color: #0f0d1d;
}

.services-details__services-list li.active span, .services-details__services-list li:hover span {
    color: #123962;
    transform: scale(1);
}

.services-details__services-list li+li {
    margin-top: 5px;
}

.services-details__help-box {
    position: relative;
    display: block;
    padding: 43px 50px 41px;
    margin-top: 30px;
    background-color: #123962;
}

.services-details__help-box-title {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 33px;
    z-index: 10;
    position: relative;
}

.services-details__help-box-text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #c3d4ff;
    position: relative;
    z-index: 10;
    line-height: 32px;
    margin-bottom: 31px;
}

.services-details__phone {
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    display: block;
    position: relative;
    transition: all 500ms ease;
    z-index: 10;
}