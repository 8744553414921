.industries {
  background-color: #f2f4f8;
  margin-top: -60px;
  position: relative;
  display: block;
  padding: 120px 0 78px;
}

.industries__top-text {
  font-family: "Jost", sans-serif;
  color: #726f84;
  font-size: 16px;
  margin: 0;
}

.industries__content-box {
  position: relative;
  display: block;
  padding-top: 48px;
}

.industries__single {
  position: relative;
  display: inline-block;
  max-width: 280px;
  width: 100%;
  text-align: center;
  padding: 0 35px;
  margin-bottom: 30px;
}

.industries__icon {
  position: relative;
  height: 112px;
  width: 112px;
  margin: 0 auto;
  color: #123962;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 10px 60px 0px rgb(0 0 0 / 7%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  z-index: 1;
}

.industries__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: #0f0d1d;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

[className^="icon-"],
[className*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.industries__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  margin-top: 26px;
  margin-bottom: 15px;
}

.industries__title a {
  font-family: "Jost", sans-serif;
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    87% / 0px 2px no-repeat;
    
  transition: all 500ms ease;
}

.industries__title a:hover {
  background-size: 100% 2px;
}

.industries__text {
  font-size: 16px;
  margin: 0;
  font-family: "Jost", sans-serif;
  color: #726f84;
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
}

.industries__single:before {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 12px;
    content: "";
    width: 1px;
    background-color: #e6e9ef;
}

