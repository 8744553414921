.locations {
    position: relative;
    display: block;
    padding-bottom: 90px;
    font-family: "Jost", sans-serif;
}

.location__inner {
    position: relative;
    display: block;
    border-top: 1px solid #e6e9ef;
    padding-top: 120px;
}

.section-title {
    margin-top: -12px;
    margin-bottom: 50px;
}

.locations__single {
    position: relative;
    display: block;
    background-color: #f2f4f8;
    padding: 45px 50px 40px;
    margin-bottom: 30px;
    transition: all 500ms ease;
}

.locations__single:hover .locations__title {
    color: white;
}
.locations__single:hover .locations__text {
    color: white;
}
.locations__single:hover .locations__mail {
    color: white;
}
.locations__single:hover .locations__phone {
    color: white;
}
.locations__single:hover {
    background-color:#123962;
}

.locations__title {
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 33px;
    transition: all 500ms ease;
}

.locations__text {
    font-size: 16px;
    margin: 0;
    line-height: 30px;
    transition: all 500ms ease;
}

.locations__mail-phone-box {
    font-size: 16px;
    color: #726f84;
    font-weight: 500;
    line-height: 30px;
}

.locations__mail {
    color: #726f84;
    display: block;
    transition: all 500ms ease;
}

.locations__phone {
    color: #726f84;
    display: block;
    transition: all 500ms ease;
}

a {
    color: #726f84;
}