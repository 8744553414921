.cases-one {
    position: relative;
    display: block;
    background-color: #f2f4f8;
    padding: 180px 0 90px;
    margin-top: -60px;
}

.cases-one__single {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.cases-one__img-box {
    position: relative;
    display: block;
    overflow: hidden;
}

.cases-one__img {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: #0f0d1d;
}

.cases-one__img > img {
    width: 100%;
    /* mix-blend-mode: luminosity; */
    transform: scale(1);
    opacity: .7;
    transition: all 500ms ease;
}

.cases-one__content {
    padding: 48px 50px 44px;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 40px;
    z-index: 1;
}

.cases-one__content:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    background-color: #123962;
    z-index: -1;
    transform: translateY(100%);
    transition: all 500ms ease;
}

.cases-one__icon span {
    font-size: 65px;
    color: var(--thm-base);
    line-height: 65px;
    display: block;
    transform: scale(1);
    transform-origin: left center;
    transition: transform 500ms ease;
}

.cases-one__title {
    font-family: "Jost", sans-serif;
    font-size: 30px;
    color: #ffffff;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 34px;
}

.cases-one__title a {
    color: var(--thm-base);
    transition: all 500ms ease;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 87% / 0px 2px no-repeat;
}

.cases-one__single:hover .cases-one__img>img {
    transform: scale(1.05);
    opacity: 1;
}

.cases-one__single:hover .cases-one__content:before {
    transform: translateY(0);
}

.cases-one__title a:hover {
  background-size: 100% 2px;
}

.cases-one__single:hover .cases-one__icon span {
    transform: scale(.85);
}
