.two-boxes {
    position: relative;
    display: block;
    z-index: 2;
    counter-reset: twoBoxesCount;
}

.two-boxes__single {
    position: relative;
    display: block;
    background-color: #ffffff;
    box-shadow: 0px 10px 60px 0px rgb(0, 0, 0, 0.10);
    padding: 60px 60px 52px;
    counter-increment: twoBoxesCount;
}

.two-boxes__single:hover .two-boxes__count{
    font-family: "Jost", sans-serif;
    height: 68px;
    width: 68px;
    background-color: #123962;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 0;
    transition: all 500ms ease;
    color:  #ebf1ff;
}

.two-boxes__single-content {
    position: relative;
    display: block;
    padding-left: 68px;
}

.two-boxes__count {
  font-family: "Jost", sans-serif;
    height: 68px;
    width: 68px;
    background-color: #ebf1ff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 0;
    transition: all 500ms ease;
    color: #123962;
}

.two-boxes__content {
    margin-left: 30px;
}

.two-boxes__count span::before {
    content: counters(twoBoxesCount, ".", decimal-leading-zero);
}

.two-boxes__count span {
    font-size: 24px;
    color: currentColor;
    font-weight: 600;
}

.two-boxes__title {
  font-family: "Jost", sans-serif;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 30px;
    margin-bottom: 11px;
    margin-top: -6px;
}

.two-boxes__text {
  font-family: "Jost", sans-serif;
    font-size: 16px;
    margin: 0;
}