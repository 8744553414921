.page-header {
    font-family: "Jost",sans-serif;
    position: relative;
    display: block;
    padding: 244px 0 139px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f0d1d;
}

.about-page-header__bg {
    /* background-image: url(../../assets/backgrounds/about-page-header-bg.jpg); */
    background-size: cover;
    background-color: #0f0d1d;
    background-blend-mode: luminosity;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.page-header__inner {
    position: relative;
    display: block;
    text-align: center;
    z-index: 3;
}

.thm-breadcrumb {
    padding-top: 3px;
}

.thm-breadcrumb li {
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 500ms ease;
}

.thm-breadcrumb li a {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 500ms ease;
}

.thm-breadcrumb li+li {
    margin-left: 8px;
}

.page-header__inner h2 {
    font-size: 50px;
    color: #ffffff;
    line-height: 52px;
    font-weight: 900;
    text-transform: uppercase;
}