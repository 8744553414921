.services-details__left {
    position: relative;
    display: block;
}

.services-details__img {
    position: relative;
    display: block;
    background-color: #0f0d1d;
}

.services-details__img > img {
    width: 100%;
    /* mix-blend-mode: luminosity; */
}

.services-details__top-content {
    margin-top: 42px;
    margin-bottom: 53px;
}

.services-details__top-title {
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 46px;
    margin-bottom: 18px;
}

.services-details__top-text {
    font-size: 16px;
    margin: 0;
}

.services-details__planning {
    position: relative;
    display: flex;
}

.services-details__planning-content {
    margin-left: 45px;
    margin-top: -8px;
}

.services-details__planning-title {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 34px;
    margin-bottom: 20px;
}

.services-details__planning-text {
    font-size: 16px;
    margin: 0;
}

.services-details__planning-list {
    padding-top: 33px;
}

.services-details__planning-list li {
    position: relative;
    display: flex;
    align-items: center;
}

.services-details__planning-list li .icon {
    position: relative;
    top: 3px;
}

.services-details__planning-list li .icon span {
    color: #123962;
    font-size: 20px;
    display: block;
    transform: scale(1);
    transition: transform 500ms ease;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}