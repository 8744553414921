.cta-two {
  font-family: "Jost", sans-serif;
  position: relative;
  display: block;
  border-top: 1px solid #e6e9ef;
  padding: 108px 0 120px;
}

.cta-two-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.cta-two-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.cta-two__inner {
  position: relative;
  display: block;
  text-align: center;
}

.cta-two__title {
  font-size: 50px;
  color: #123962;
  font-weight: 900;
  line-height: 60px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.cta-two__text {
  font-size: 24px;
  margin: 0;
}

.cta-two__icon-box {
  position: relative;
  display: block;
  padding-top: 35px;
}

.cta-two__icon-box li {
  position: relative;
  display: inline-block;
}

.cta-two__icon-box li a {
  height: 77px;
  width: 77px;
  background-color: #0f0d1d;
  font-size: 35px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 500ms ease;
}

[className^="icon-"],
[className*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
