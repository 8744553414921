.faq-one {
    position: relative;
    display: block;
    padding: 120px 0 120px;
    font-family: "Jost", sans-serif;
}

.section-title {
    margin-top: -12px;
    margin-bottom: 50px;
}

.section-title__title {
    margin: 0;
    font-weight: 900;
    color: #0f0d1d;
    font-size: 50px;
    line-height: 60px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.section-title__tagline {
    position: relative;
    display: inline-block;
    color: #726f84;
    font-size: 18px;
    align-items: center;
    line-height: 30px;
    font-weight: 500;
}

.faq-one-accrodion {
    position: relative;
    display: block;
    counter-reset: count;
}

.faq-one-accrodion .accrodion.active {
    border: 1px solid #e6e9ef;
    background-color: var(--thm-base);
    box-shadow: 0px 0px 30px 0px rgb(0, 0, 0, .05);
}

.faq-one-accrodion .accrodion.active .accrodion-title {
    background-color: transparent;
}

.faq-one-accrodion .accrodion-title {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 22px 0px 22px;
    padding-top: 23.5px;
    padding-bottom: 23.5px;
    padding-left: 40px;
    padding-right: 30px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
    background-color: #f2f4f8;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4 {
    color: #0f0d1d;
}

.faq-one-accrodion .accrodion-title h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 800;
    line-height: 28px;
    text-transform: uppercase;
    position: relative;
    transition: all 500ms ease;
}

/* .faq-one-accrodion .accrodion.active .accrodion-title h4::before {
    content: "\f068";
    color: #0f0d1d;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
} */

/* .faq-one-accrodion .accrodion-title h4::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: #123962;
    position: absolute;
    top: 50%;
    right: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    transition: all 500ms ease;
} */

.faq-one-accrodion .accrodion-title h4 span {
    position: relative;
    display: inline-block;
    color: #123962;
}

.faq-one-accrodion .accrodion-title h4 span:before {
    counter-increment: count;
    content: "" counter(count);
}

.faq-one-accrodion .accrodion-content {
    position: relative;
    padding: 0px 40px 28px;
    border-bottom: 0px solid #e5e5e5;
}

.faq-one-accrodion .accrodion-content p {
    margin: 0;
    font-size: 16px;
    color: #726f84;
    line-height: 34px;
    font-weight: 500;
}

.faq-one__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 42px;
}

.faq-one__list-box {
    position: relative;
    display: block;
}

.faq-one__list li {
    display: flex;
    align-items: center;
}

.faq-one__list li .text {
    margin-left: 20px;
}

.faq-one__list li .text p {
    margin: 0;
    font-weight: 400;
    color: #726f84;
    font-size: 18px;
    line-height: 34px;
}

.faq-one__list li+li {
    margin-top: 6px;
}

@media (max-width:500px) {
    .faq-one__img > img{
        width: 100%;
    }
}