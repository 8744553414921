.service-submenu {
  width: 10rem;
  position: absolute;
  list-style: none;
  margin: 0px;
  padding: 0px;
  text-align: start;
  top: 73px;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Add transitions */
  z-index: 10;
}

.service-submenu.visible {
  opacity: 1;
  visibility: visible;
}

.service-submenu li {
  background-color: white;
  list-style: none;
  padding: 16px;
  width: 10rem;
  transition: background-color 0.4s ease-in-out;
}

.service-submenu li:hover {
  background-color: #123962;
  cursor: pointer;
}

.service-submenu li:hover a {
  color: black;
  cursor: pointer;
  transition: color 0.4s ease-in-out;
}

.service-submenu li a {
  font-family: "Jost", sans-serif;
  text-decoration: none;
  color: #0f0d1d;
  font-weight: bold;
  transition: color 0.3s ease; /* Add transition for link color */
}
