.side-bar {
  position: fixed;
  z-index: 92;
  background-color: #0f0d1d;
  width: 100%;
  height: 100%;
}

.mob-side-header{
  display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
}

#mob-main-navbar{
     display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 30px;
    padding-left: 0px;
}

#mob-main-navbar .navbar-li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#mob-main-navbar li .navbar-a {
  font-family: "Jost", sans-serif;
  text-decoration: none;
  font-size: 14px;
  font-weight: bolder;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: transform 500ms ease;
}