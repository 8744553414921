.cta-one {
  position: relative;
  display: block;
  background-color: #123962;
  padding: 110px 0;
}

.cta-one-bg {
  background-image: url(../assets/backgrounds/cta-one-bg.png);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center center;
  background-size: cover;
  background-color: #123962;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
}

.cta-one_inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cta-one_title {
  font-family: "Jost", sans-serif;
  font-size: 35px;
  color: white;
  line-height: 50px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: var(--thm-letter-space-small);
}

.cta-one_right .cta-one_btn {
  background-color: white;
  color: black;
}

.cta-one_right .thm-btn {
  font-family: "Jost", sans-serif;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: none;
  outline: none !important;
  background-color: white;
  color: #123962;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 18px 50px;
  transition: 500ms;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.cta-one_right .thm-btn:hover {
  font-family: "Jost", sans-serif;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: none;
  outline: none !important;
  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 18px 50px;
  transition: 500ms;
  letter-spacing: 0.1em;
  text-decoration: none;
}

@media (max-width: 1400px) {
  .cta-one_inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
}
