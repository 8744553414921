.our-mission {
  position: relative;
  display: block;
  padding: 109px 0 120px;
  background-color: #0f0d1d;
}

.our-mission-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0f0d1d;
  background-blend-mode: luminosity;
  opacity: 0.5;
}

.parallax {
  background-image: url("../../assets/backgrounds/our-mission-bg.jpg");
  min-height: 200px;
  background-attachment: fixed;
  background-position: center;
}

.our-mission__inner {
  position: relative;
  display: block;
  text-align: center;
}

.our-mission__title {
  font-family: "Jost", sans-serif;
  font-size: 45px;
  color: #ffffff;
  font-weight: 700;
  line-height: 70px;
  text-transform: capitalize;
  margin-bottom: 46px;
}
