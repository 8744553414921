.welcome-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.container {
  padding-left: 100px;
  padding-right: 100px;
}

.welcome-one__left {
  position: relative;
  display: block;
  margin-right: 60px;
}

.welcome-one__img-box {
  position: relative;
  display: block;
}

.welcome-one__img-1 {
  background-color: #0f0d1d;
}

.welcome-one__img-1 > img {
  width: 100%;
}

.welcome-one__img-2 {
  background-color: #0f0d1d;
  border-top: 20px solid white;
  border-left: 20px solid white;
  position: absolute;
  bottom: -60px;
  right: -20px;
}

img,
svg {
  vertical-align: middle;
}

.welcome-one__right {
  position: relative;
  display: block;
}

.welcome-one__title {
  font-family: "Jost", sans-serif;
  font-size: 50px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 60px;
  margin-bottom: 37px;
  margin-top: -12px;
  letter-spacing: var(--thm-letter-space-small);
}

.welcome-one__text {
  font-family: "Jost", sans-serif;
  margin: 0;
  padding-bottom: 37px;
}

.welcome-one__text-two {
  font-family: "Jost", sans-serif;
  font-size: 26px;
  color: #123962;
  font-weight: 400;
  line-height: 45px;
}

.welcome-one__call {
  display: flex;
  align-items: center;
  padding-top: 35px;
}

.welcome-one__call-icon {
  height: 68px;
  width: 68px;
  background-color: #ebf1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: #123962;
  transition: all 500ms ease;
}
.welcome-one__call-icon:hover {
  height: 68px;
  width: 68px;
  background-color: #123962;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: #ebf1ff;
  transition: all 500ms ease;
}

.welcome-one__call-text {
  font-family: "Jost", sans-serif;
  margin-left: 30px;
  color: #726f84;
}

.welcome-one__call-text p {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 800px) {
  .welcome-one__right {
    margin-top: 90px;
  }

  .welcome-one__img-2  > img{
    width: 15em;
  }
}
