.main-header {
  background: transparent;
  position: absolute;
  padding: 5px 70px;
  display: flex;
  height: 7em;
  transition: all 500ms ease;
  z-index: 91;
  width: 100%;
  left: 0px;
  top: 0px;
}

.main-header:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  background-color: rgba(255, 255, 255, 0.15);
}

.header-logo {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  padding-right: 5em;
  justify-content: center;
  display: flex;
  align-items: center;
}

.header-items {
  display: flex;
  align-items: center;
}

#main-navbar {
  display: flex;
  align-items: center;
  gap: 20px;
}

#main-navbar .navbar-li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#main-navbar li .navbar-a {
  font-family: "Jost", sans-serif;
  text-decoration: none;
  font-size: 14px;
  font-weight: bolder;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: transform 500ms ease;
}

#main-navbar li .navbar-a:hover::after {
  content: "";
  background: white;
  width: 10%;
  height: 2px;
  position: absolute;
  bottom: -6px;
  left: 20px;
  transition: transform 500ms ease;
}

#main-navbar li .navbar-a:hover::after {
  transform: scaleX(1);
}

#main-navbar li .navbar-a::after {
  content: "";
  background: white;
  width: 10%;
  height: 2px;
  position: absolute;
  bottom: -6px;
  left: 20px;
  transition: transform 500ms ease;
  transform: scaleX(0);
}

.main-info {
  font-family: "Jost", sans-serif;
  color: #f2f4f8;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  justify-content: center;
}

.main-info a {
  font-family: "Jost", sans-serif;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.main-info a:hover {
  font-family: "Jost", sans-serif;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #123962;
}

.mobile-slide {
  font-family: "Jost", sans-serif;
  color: #f2f4f8;
  font-size: 50px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  justify-content: center;
  display: none;
}

@media (max-width: 1300px) {
  .header-logo {
    border-right: none
}
  .header-items {
    display: none;
  }
  .main-info {
    display: none;
  }
  .mobile-slide {
    display: block;
  }
}
