.team-one {
    position: relative;
    display: block;
    padding: 120px 0 90px;
}

.team-one__container {
    position: relative;
    display: block;
    max-width: 1780px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}